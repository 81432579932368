import {
  QueryFunctionContext,
  queryFetch,
  useReactInfiniteQuery,
} from "@cultureamp/frontend-apis"

// we extracted the load of task list data to its hook to be reusable between tile and old tasks
export const useTaskListData = ({
  filter = "active",
  numberOfTasks,
}: {
  filter?: "active" | "completed"
  numberOfTasks?: number
} = {}) => {
  return useReactInfiniteQuery({
    queryKey: [
      "tasks/[filter]",
      {
        params: { filter },
        query: {
          take: numberOfTasks,
        },
      },
    ],
    queryFn: (context: QueryFunctionContext<any, string | undefined>) =>
      queryFetch("tasks/[filter]", {
        params: { filter },
        query: { take: numberOfTasks?.toString(), after: context.pageParam },
        timeout: 10000,
      }),
    initialPageParam: undefined,
    getNextPageParam: lastPage => lastPage.pageInfo.endCursor,
  })
}
