import { OnboardingQuestionnaireCompletedProperties } from "src/Avo"
import { RequiredKeysTuple } from "./types"
import { queryFetch, useReactQuery } from "@cultureamp/frontend-apis"
import { useCallback, useEffect } from "react"
import { useLocalStorageValue, useSessionStorageValue } from "@react-hookz/web"
import { useNavigate } from "./navigation"

// ideally we would infer this from the type but that isn't possible because this check needs to happen on runtime not build time
const requiredFields: RequiredKeysTuple<OnboardingQuestionnaireCompletedProperties> =
  ["helpOption"]

export const hasRequiredData = (
  data?: Partial<OnboardingQuestionnaireCompletedProperties>,
): data is OnboardingQuestionnaireCompletedProperties => {
  if (!data) return false
  const missingKey = requiredFields.find(key => !(key in data))
  return !missingKey
}

export const useOnboardingData = () => {
  return useSessionStorageValue<
    Partial<OnboardingQuestionnaireCompletedProperties>
  >("onboarding", { initializeWithValue: false })
}

export const useOnboardingValue = (
  key: keyof OnboardingQuestionnaireCompletedProperties,
) => {
  const { value, set } = useOnboardingData()
  const setValue = useCallback(
    (newValue: OnboardingQuestionnaireCompletedProperties[typeof key]) => {
      set({ ...value, [key]: newValue })
    },
    [value, key, set],
  )

  return [value?.[key], setValue] as const
}

export const useOnboardingRedirect = () => {
  const config = useReactQuery({
    queryKey: ["configuration"],
    queryFn: () => queryFetch("configuration"),
  })
  const navigate = useNavigate()

  const { value: wasOnboarded, set: setWasOnboarded } = useLocalStorageValue(
    "hasResolvedOnboarding",
    { initializeWithValue: true },
  )

  useEffect(() => {
    if (!wasOnboarded && config.data?.shouldBeOnboarded) {
      setWasOnboarded(true)
      navigate("/onboarding")
    }
  }, [config.data?.shouldBeOnboarded, wasOnboarded, setWasOnboarded, navigate])
}
