import parseUrl from 'url-parse';
function normaliseQuery(ctx) {
  if (!ctx.options.query) return ctx.options.query;
  const query = new URLSearchParams();
  const entries = ctx.options.query instanceof URLSearchParams ? ctx.options.query.entries() : Object.entries(ctx.options.query);
  for (const [key, value] of entries) {
    if (value === undefined) continue;
    if (Array.isArray(value)) {
      value.forEach(val => {
        query.append(key, val.toString());
      });
    } else if (value === "[pageParam]" && ctx.options.pageParam !== undefined && ctx.options.pageParam !== null) {
      query.append(key, ctx.options.pageParam.toString());
    } else if (value !== "[pageParam]") {
      query.append(key, value.toString());
    }
  }
  return query;
}
const queryString = (ctx, next) => {
  const url = parseUrl(ctx.input.toString(), {}, true);
  const query = normaliseQuery(ctx);
  if (query) {
    // @ts-ignore the read-only type for `query` is comes from external @types package not specific to the url-parse library
    url.query = query.toString();
  }
  ctx.input = url.toString();
  return next();
};
export { queryString };
