import { isPlainObject } from 'is-plain-object';
import deepmerge from 'deepmerge';

// modified version of koa-compose that allows retries
function compose(middleware) {
  return (ctx, next) => {
    const dispatch = async i => {
      const fn = i === middleware.length ? next : middleware[i];
      if (!fn) return undefined;
      return fn(ctx, () => dispatch(i + 1));
    };
    return dispatch(0);
  };
}
/**
 * Make a fetch function that will process all middlewares
 */
function fetchFactory(middlewares, defaultOptions = {}) {
  const flow = compose(middlewares);
  // it complains that the return function is not strict enough to fit the return type
  // but that is exactly what we want to do here, make it type strict here
  // @ts-ignore
  return async (input, options = {}) => {
    const ctx = {
      input,
      options: deepmerge(defaultOptions, options, {
        isMergeableObject: isPlainObject
      })
    };
    await flow(ctx);
    if (ctx.response) return ctx.response;
    throw new Error("No fetch middleware set response or throw error, check if you have correctly initialized fetch");
  };
}
export { fetchFactory };
