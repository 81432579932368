import { AdminTileTracker } from "./AdminTileTracker"
import { FormattedMessage } from "@cultureamp/i18n-react-intl"
import { Heading } from "@kaizen/components"
import EmployeeDataTile from "src/tiles/EmployeeDataTile/EmployeeDataTile"
import PerformanceTile from "src/tiles/PerformanceTile/PerformanceTile"
import React from "react"
import RetentionRateTile from "src/tiles/RetentionRateTile/RetentionRateTile"
import SurveyTile from "src/tiles/SurveyTile/SurveyTile"
import type { HomeLayoutFlags } from "pages/api/home/types"

type AdminSectionProps = {
  isLoading: boolean
  flags: Pick<
    HomeLayoutFlags,
    | "analyticsRetentionEnable"
    | "hasPerformance"
    | "hasEngagement"
    | "hasPerformanceAdminRole"
    | "hasAccountAdminRole"
    | "hasSelfStarterAccount"
    | "hasStandardAccount"
    | "hasEmployeeDataAdminRole"
  >
}

const getAdminTiles = (flags: AdminSectionProps["flags"]) => {
  if (
    flags.hasEmployeeDataAdminRole &&
    !flags.hasAccountAdminRole &&
    !flags.hasPerformanceAdminRole
  ) {
    return [{ Component: EmployeeDataTile }]
  }

  if (flags.hasPerformance && flags.hasPerformanceAdminRole) {
    return flags.hasEngagement
      ? [{ Component: PerformanceTile }, { Component: SurveyTile }]
      : [{ Component: EmployeeDataTile }, { Component: PerformanceTile }]
  }

  return [{ Component: EmployeeDataTile }, { Component: SurveyTile }]
}

const OrganizationSection: React.FC<AdminSectionProps> = ({
  flags,
  isLoading,
}) => {
  if (!flags.hasAccountAdminRole && !flags.hasEmployeeDataAdminRole) return

  const isSelfStarterOrStandardAdmin =
    flags.hasSelfStarterAccount || flags.hasStandardAccount

  const shouldShowRetentionTile =
    flags.hasEngagement && flags.analyticsRetentionEnable

  const adminTiles = getAdminTiles(flags)

  return (
    <div className="flex flex-col gap-24">
      <Heading variant="heading-2">
        <FormattedMessage
          id="admin.section.title"
          defaultMessage="Your organization"
          description="Title of section displaying information relevant to your organisation"
        />
      </Heading>

      {shouldShowRetentionTile && (
        <RetentionRateTile isInFeed isWarmup={isLoading} />
      )}
      {isSelfStarterOrStandardAdmin && (
        <AdminTileTracker numberOfTilesShown={adminTiles.length}>
          <div className="flex flex-wrap gap-24 [&>div]:flex-1">
            {adminTiles.map(({ Component }) => (
              <Component key={Component.name} />
            ))}
          </div>
        </AdminTileTracker>
      )}
    </div>
  )
}

export default OrganizationSection
