import { FeedContainer } from "src/feed/components/FeedContainer/FeedContainer"
import { OneColumn } from "src/components/LayoutContainers/LayoutContainers"
import { SkirtContainer } from "src/components/LayoutContainers/LayoutContainers"
import { TileDisplayedTracker } from "src/components/TileDisplayedTracker/TileDisplayedTracker"
import ExploreTilesSection from "src/components/ExploreTilesSection/ExploreTilesSection"
import OrganizationSection from "src/components/OrganizationSection/OrganizationSection"
import React, { FC } from "react"
import TasksTile from "src/tiles/TasksTile/TasksTile"
import TitleTile from "src/tiles/TitleTile/TitleTile"
import type { HomeLayoutFlags } from "../api/home/types"

type ContentProps = {
  isLoading: boolean
  flags: HomeLayoutFlags | null | undefined
}

const getNumberOfTiles = (flags: HomeLayoutFlags | null | undefined) => {
  const shouldShowRetentionTile =
    flags?.hasEngagement && flags?.analyticsRetentionEnable

  // Number of tiles in layout:
  // TitleTile: 1
  // TasksTile: 1
  // AdminSection: 2 (+1 if include retention tile)
  // ExploreTileSection: 4
  return shouldShowRetentionTile ? 7 : 6
}

const HomeConnect: FC<{
  flags: HomeLayoutFlags | null | undefined
  isLoading: boolean
}> = ({ flags, isLoading }) => (
  <OneColumn>
    <FeedContainer
      hasPerformance={flags?.hasPerformance}
      hasPerformanceReviews={flags?.hasPerformanceReviews}
    />
    {flags && <OrganizationSection flags={flags} isLoading={isLoading} />}
    <ExploreTilesSection flags={flags} />
  </OneColumn>
)

const Content: FC<ContentProps> = ({ isLoading, flags }) => (
  <TileDisplayedTracker numberOfTilesShown={getNumberOfTiles(flags)}>
    <TitleTile
      isWarmup={isLoading}
      hasFeedbackCTA={!!(flags?.hasPerformance && flags?.hasPerformanceReviews)}
      hasContinuousPerformanceAnytimeFeedback={
        !!flags?.hasContinuousPerformanceAnytimeFeedback
      }
    />
    <SkirtContainer>
      <TasksTile
        hasOldTasks={
          !!(flags && (flags.hasEngagement || flags.hasEffectiveness))
        }
        isWarmup={isLoading}
      />
    </SkirtContainer>
    <HomeConnect flags={flags} isLoading={isLoading} />
  </TileDisplayedTracker>
)

export default Content
