import {
  Container,
  Content,
  Heading,
  HeadingProps,
  LoadingHeading,
  Skirt,
  SkirtCard,
  TileElement,
  TileGrid,
} from "@kaizen/components"
import { FormattedMessage } from "@cultureamp/i18n-react-intl"
import { useIsLarge } from "src/helpers/responsiveHooks"
import React, { PropsWithChildren } from "react"
import Spacer from "../Spacer/Spacer"
import classnames from "classnames"
import styles from "./LayoutContainers.module.scss"

export const OneColumn: React.FC<
  PropsWithChildren<{}> & { marginLarge?: boolean }
> = ({ children, marginLarge }) => (
  <Container
    classNameOverride={classnames(styles.container, {
      [styles.marginLarge]: marginLarge,
    })}
  >
    <Content>
      <div className={styles["col-1"]}>{children}</div>
    </Content>
  </Container>
)

export const TwoColumnsWithSkirt: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => (
  <Skirt>
    <Spacer gap={2} />
    <div className={styles["col-2"]}>{children}</div>
  </Skirt>
)

export const TwoColumns: React.FC<
  PropsWithChildren<{}> & { marginLarge?: boolean }
> = ({ children, marginLarge }) => (
  <Container
    classNameOverride={classnames(styles.container, {
      [styles.marginLarge]: marginLarge,
    })}
  >
    <Content>
      <div className={styles["col-2"]}>{children}</div>
    </Content>
  </Container>
)

export const TileGridContainer: React.FC<{
  children: TileElement | TileElement[]
}> = ({ children }) => (
  <OneColumn>
    <TileGrid classNameOverride="pl-0">{children}</TileGrid>
  </OneColumn>
)

export const SkirtContainer: React.FC<{
  isWarmup?: boolean
  children: TileElement | TileElement[]
}> = ({ isWarmup, children }) => {
  const islarge = useIsLarge()
  return (
    /*
       TEMPORARY FIX: it's not the correct use of titleBlockHasNavigation.
       We temporarily need extra height on the skirt on the desktop (by sending the prop to be false),
       and turn it back to default height on non desktop so the skirt will not show in between children of skirt container.
       The source code refers to https://github.com/cultureamp/kaizen-design-system/blob/1880d2b202dbd77caaae2b39bff08a72727e266b/draft-packages/page-layout/KaizenDraft/Skirt/Skirt.tsx#L76
    */
    <Skirt
      titleBlockHasNavigation={!islarge}
      classNameOverride={styles.hideSkirtOnMobileAndTablet}
    >
      <div className={`${styles.heading} ${styles.onlyLarge}`}>
        <HeadingText isWarmup={isWarmup} color="white" />
      </div>
      <SkirtCard
        classNameOverride={`${styles.heading} ${styles.onlyMediumAndSmall}`}
      >
        <HeadingText isWarmup={isWarmup} />
      </SkirtCard>
      {children}
    </Skirt>
  )
}

const HeadingText: React.FC<{
  isWarmup?: boolean
  color?: HeadingProps["color"]
}> = ({ isWarmup, color }) => {
  if (isWarmup) {
    return <LoadingHeading variant="heading-2" />
  }

  return (
    <Heading variant="heading-2" color={color}>
      <FormattedMessage
        id="tasks.title"
        defaultMessage="Tasks"
        description="Title of section that displays a list of tasks that need to be completed by the user"
      />
    </Heading>
  )
}
