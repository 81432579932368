import { Divider } from "@kaizen/components"
import React, { PropsWithChildren } from "react"
import styles from "./TileFooter.module.scss"

export const TileFooter: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className={styles.footer}>
    <Divider variant="canvas" />
    <div className="mt-24 mb-6">{children}</div>
  </div>
)
