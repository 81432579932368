import { determineEnvironment } from '@cultureamp/frontend-env/lib';
import { join } from './localEndpointPrefix.mjs';

// This is needed because when we write tests for components which perform network requests (through useQuery, useMutation or fetch directly),
// in node a path-relative URL (/my/locale) would result in a reject Promise
// Therefore, this would add window.location.origin so that it would become an absolute url so node-fetch would not reject and make the request
// msw would then be able to intercept it
const addWindowOriginToRelativePath = relativePath => join(window.location.origin, relativePath.toString());
const testEnv = async (ctx, next) => {
  if (determineEnvironment().realm !== "test") {
    return next();
  }
  if (typeof window !== "undefined") {
    const isRelativePath = ctx.input.toString().startsWith("/");
    if (isRelativePath) {
      ctx.input = addWindowOriginToRelativePath(ctx.input.toString());
    }
  }
  return next();
};
export { testEnv };
