// we use own join instead of builtin from node so it works well on client and server
function join(...segments) {
  return segments.map(path => path === null || path === void 0 ? void 0 : path.replace(/^\/|\/$/g, "")).filter(Boolean).join("/");
}
function getBffPathPrefixForOldFETemplate() {
  var _a;
  // @cultureamp/frontend-runtime is setting this one so we don't need to access window
  // https://github.com/cultureamp/frontend-ops/blob/9f8dc8cc408aa150916ccd379a6669fdff7819f7/packages/frontend-runtime/lib/index.js#L25
  if (typeof BFF_PATH_PREFIX !== "undefined") return BFF_PATH_PREFIX;
  // this will work with all frontend template apps even during local development
  // https://github.com/cultureamp/frontend-ops/blob/4f1aedaefed9ec7a85ccf613a979a788b93f00c6/packages/frontend-build/lib/dev.ts#L62
  if (typeof window !== "undefined") {
    return (_a = window.document.querySelector("meta[name='bff-path-prefix']") // frontend-template
    ) === null || _a === void 0 ? void 0 : _a.getAttribute("content");
  }
  return undefined;
}
function prefixUrlForNextStack(url, basePath) {
  return join(basePath, "api", url); // app/home/api/**** */
}
function prefixUrl(options, url) {
  // for package that consumes new Nextjs stack BFF
  if (options.basePath) {
    return join(options.basePath, "api", url);
  }
  // for FE app using new Nextjs stack
  // BASE_PATH is set via packages/frontend-apis/src/nextjs/withFrontEndApis.ts which is a next js plugin set up in next.config.js
  if (typeof process.env.BASE_PATH !== "undefined") {
    return prefixUrlForNextStack(url, process.env.BASE_PATH);
  }
  // for FE still using old FE template
  const feTemplateBffPathPrefix = getBffPathPrefixForOldFETemplate();
  if (feTemplateBffPathPrefix) {
    return join(feTemplateBffPathPrefix, url);
  }
  // we don't have any indication what to do so we play the safest option as default fallback
  // if you run into some environment that gets here and shouldn't, please add `process.env.BASE_PATH` in that environment
  return join("/bff/local", url);
}
const localEndpointPrefix = (ctx, next) => {
  const url = ctx.input.toString();
  // for urls that include protocol or absolute path don't do a thing
  if (url.includes("://") || url.startsWith("/")) return next();
  ctx.input = "/" + prefixUrl(ctx.options, url);
  return next();
};
export { join, localEndpointPrefix, prefixUrl };
