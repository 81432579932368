import { FeedLoading } from "./FeedLoading"
import { FormattedMessage } from "@cultureamp/i18n-react-intl"
import { Heading } from "@kaizen/components"
import { PropsWithChildren } from "react"
import { queryFetch, useReactQuery } from "@cultureamp/frontend-apis"
import dynamic from "next/dynamic"
import getCurrentTimeZone from "src/helpers/timezone"
import useCurrentUser from "src/helpers/useCurrentUser"
import type { HomeLayoutFlags } from "pages/api/home/types"

const EmptyFeed = dynamic(() => import("../EmptyFeed/EmptyFeed"), {
  loading: () => <FeedLoading />,
})

const FeedContent = dynamic(() => import("../FeedContent/FeedContent"), {
  loading: () => <FeedLoading />,
})

const FeedError = dynamic(() => import("./FeedError"), {
  loading: () => <FeedLoading />,
})

const Container: React.FC<PropsWithChildren> = ({ children }) => (
  <section className="mb-[32px] mt-[8px] flex flex-col gap-16">
    <Heading variant="heading-2">
      <FormattedMessage
        defaultMessage="Your feed"
        id="feed.section.heading"
        description="Title for homepage feed section"
      />
    </Heading>
    {children}
  </section>
)

export const FeedContainer: React.FC<
  Pick<HomeLayoutFlags, "hasPerformance" | "hasPerformanceReviews">
> = ({ hasPerformance, hasPerformanceReviews }) => {
  const { isLoading: feedLoading, data: feedData } = useReactQuery({
    queryKey: ["feed", { timeZone: getCurrentTimeZone() }],
    enabled: typeof window !== "undefined",
    queryFn: () =>
      queryFetch("feed", { query: { timeZone: getCurrentTimeZone() } }),
  })

  // Pre-load user data to warm up the cache for FeedItems
  // Ensures they will not have to worry about loading state because the data will be there immediately
  const { isLoading: userLoading, data: userData } = useCurrentUser()
  const isLoading = userLoading || feedLoading

  if (isLoading)
    return (
      <Container>
        <FeedLoading />
      </Container>
    )

  if (!feedData || !userData)
    return (
      <Container>
        <FeedError />
      </Container>
    )

  const { feed, suggestions, summary } = feedData

  return (
    <Container>
      {feed.length === 0 ? (
        <EmptyFeed
          summary={summary}
          flags={{ hasPerformance, hasPerformanceReviews }}
        />
      ) : (
        <FeedContent feed={feed} suggestions={suggestions} />
      )}
    </Container>
  )
}
