const requestBodyMiddleware = (ctx, next) => {
  if (!ctx.options.body) return next();
  if (typeof ctx.options.body === "object" && !(ctx.options.body instanceof FormData)) {
    ctx.options.headers = new Headers(ctx.options.headers);
    const currentContentType = ctx.options.headers.get("content-type");
    if (!currentContentType || currentContentType === "application/json") {
      ctx.options.body = JSON.stringify(ctx.options.body);
      ctx.options.headers.set("content-type", "application/json");
    }
  }
  return next();
};
export { requestBodyMiddleware };
