var FETCH_ERRORS;
(function (FETCH_ERRORS) {
  FETCH_ERRORS[FETCH_ERRORS["NETWORK_FAILURE"] = 523] = "NETWORK_FAILURE";
})(FETCH_ERRORS || (FETCH_ERRORS = {}));
const fetch = async (ctx, next) => {
  var _a, _b;
  const fetchFun = (_a = ctx.options.fetch) !== null && _a !== void 0 ? _a : globalThis.fetch;
  try {
    ctx.response = await fetchFun(ctx.input, ctx.options);
  } catch (err) {
    // error is network error like ECONNRESET ... (https://nodejs.org/api/errors.html#errors_common_system_errors)
    // so we want to create a simple response for it so we can manage it consistently with response errors
    ctx.response = new Response(JSON.stringify(err), {
      status: FETCH_ERRORS.NETWORK_FAILURE,
      statusText: (_b = err.code) !== null && _b !== void 0 ? _b : "ECONNRESET"
    });
  }
  return next();
};
export { FETCH_ERRORS, fetch };
