import { UnifiedHomeData } from "src/upstreams"
import type {
  Coaching,
  InclusionSurvey,
  Individual360,
  Informative,
  OneOnOne,
  ReadArticle,
  RemoteManager,
  Team1,
  TeamEffectiveness1,
  Video,
} from "@kaizen/components"
/**
 * note all texts are just defaults, they are translated on client side
 */

export type KaizenIllustration = {
  Coaching: typeof Coaching
  InclusionSurvey: typeof InclusionSurvey
  Individual360: typeof Individual360
  Informative: typeof Informative
  OneOnOne: typeof OneOnOne
  ReadArticle: typeof ReadArticle
  RemoteManager: typeof RemoteManager
  Team1: typeof Team1
  TeamEffectiveness1: typeof TeamEffectiveness1
  Video: typeof Video
}

export interface Tile {
  id:
    | "directory"
    | "directReports"
    | "teams"
    | "support"
    | "oneOnOne"
    | "skillsCoach"
    | "blog"
    | "community"
    | "training"
    | "threeSixty"
  title: string
  description: string
  primaryAction: {
    label: string
    href: string
  }
  illustration: {
    image: keyof KaizenIllustration
  }
}

export interface ThreeSixtyTile {
  id: "threeSixty"
  title: string
  description: string
  primaryAction: {
    label: string
    availableThreeSixties: readonly UnifiedHomeData["Useravailablethreesixties"][]
  }
  illustration: {
    image: keyof KaizenIllustration
  }
}

export const threeSixty: ThreeSixtyTile = {
  id: "threeSixty",
  title: "Drive your development",
  description: "Gather feedback and insights to fuel your personal development",
  primaryAction: {
    label: "Launch 360",
    availableThreeSixties: [
      {
        surveyName: "Individual Effectiveness, Jan 2020",
        surveyUrl: "/path/to/my/360/process",
        surveyLaunchDate: "",
      },
      {
        surveyName: "Individual Effectiveness #2, Jan 2021",
        surveyUrl: "/path/to/my/other/360/process",
        surveyLaunchDate: "",
      },
    ],
  },
  illustration: {
    image: "Individual360",
  },
}

export const directory: Tile = {
  id: "directory",
  title: "Find a colleague",
  description:
    "Quickly give and request feedback for any employee in your company",
  primaryAction: {
    label: "Search directory",
    href: "/performance/search",
  },
  illustration: {
    image: "TeamEffectiveness1",
  },
}

export const directReports: Tile = {
  id: "directReports",
  title: "Direct reports",
  description:
    "View information about the employees who report directly to you",
  primaryAction: {
    label: "View direct reports",
    href: "/performance/team_search",
  },
  illustration: {
    image: "RemoteManager",
  },
}

export const teams: Tile = {
  id: "teams",
  title: "Cross-functional teams",
  description: "View cross-functional teams you belong to and team goals",
  primaryAction: {
    label: "Explore teams",
    href: "/performance/teams",
  },
  illustration: {
    image: "Team1",
  },
}

export const support: Tile = {
  id: "support",
  title: "Support guide",
  description:
    "Access tips and tricks about how to use the Culture Amp platform",
  primaryAction: {
    label: "Search the guide",
    href: "https://support.cultureamp.com/",
  },
  illustration: {
    image: "Informative",
  },
}

export const oneOnOne: Tile = {
  id: "oneOnOne",
  title: "Start a conversation",
  description: "Take your 1-on-1s beyond simple status updates",
  primaryAction: {
    label: "View 1-on-1s",
    href: "/app/conversations",
  },
  illustration: {
    image: "OneOnOne",
  },
}

export const skillsCoach: Tile = {
  id: "skillsCoach",
  title: "Skills coach",
  description:
    "Using behavioral science and spaced repetition to drive real change",
  primaryAction: {
    label: "View skills coach",
    href: "/app/skills-coach",
  },
  illustration: {
    image: "Coaching",
  },
}

export const blog: Tile = {
  id: "blog",
  title: "Culture Amp blog",
  description: "Articles about company culture and  employee experience",
  primaryAction: {
    label: "Explore the blog",
    href: "https://www.cultureamp.com/blog",
  },
  illustration: {
    image: "ReadArticle",
  },
}

export const community: Tile = {
  id: "community",
  title: "Culture First Community",
  description: "Our online community is a great place to ask questions",
  primaryAction: {
    label: "Join the community",
    href: "https://www.cultureamp.com/community",
  },
  illustration: {
    image: "InclusionSurvey",
  },
}

export const training: Tile = {
  id: "training",
  title: "On-demand training",
  description: "Get the most from Culture Amp with on-demand and live training",
  primaryAction: {
    label: "Master our platform",
    href: "/redirect/training",
  },
  illustration: {
    image: "Video",
  },
}
