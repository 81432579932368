import { dispatchForbiddenEvent } from '../fetch/middlewares/dispatchForbiddenEvent.mjs';
import { fetchFactory } from '../fetch/fetchFactory.mjs';
import { fetchWithPreload } from '../fetch/middlewares/fetchWithPreload.mjs';
import { localDev } from '../fetch/middlewares/localDev.mjs';
import { localEndpointPrefix } from '../fetch/middlewares/localEndpointPrefix.mjs';
import { queryString } from '../fetch/middlewares/queryString.mjs';
import { redirectUnauthorised } from '../fetch/middlewares/redirectUnauthorised.mjs';
import { requestBodyMiddleware } from '../fetch/middlewares/requestBodyMiddleware.mjs';
import { responseBody, responseError } from '../hooks/responseHandler.mjs';
import { testEnv } from '../fetch/middlewares/testEnv.mjs';
import { timeout } from '../fetch/middlewares/timeout.mjs';
import { upcaseMethod } from '../fetch/middlewares/upcaseMethod.mjs';
import { urlParamsMiddleware } from '../fetch/urlParamsMiddleware.mjs';
const QUERY_FETCH_MIDDLEWARES = [upcaseMethod, localEndpointPrefix, urlParamsMiddleware, queryString, requestBodyMiddleware, localDev, testEnv, timeout, redirectUnauthorised, dispatchForbiddenEvent, fetchWithPreload];
const queryFetchWithMiddlewares = fetchFactory(QUERY_FETCH_MIDDLEWARES);
const queryFetch = (url, options) => ({
  args: {
    url,
    options
  },
  then: async resolve => {
    const response = await queryFetchWithMiddlewares(url, options);
    if (response.ok) {
      return resolve(await responseBody(response));
    } else {
      resolve(responseError(response, url));
    }
  }
});
export { queryFetch };
