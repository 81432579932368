import { fetch } from './middlewares/fetch.mjs';
import { fetchFactory } from './fetchFactory.mjs';
import { localDev } from './middlewares/localDev.mjs';
import { localEndpointPrefix } from './middlewares/localEndpointPrefix.mjs';
import { queryString } from './middlewares/queryString.mjs';
import { requestBodyMiddleware } from './middlewares/requestBodyMiddleware.mjs';
import { testEnv } from './middlewares/testEnv.mjs';
import { timeout } from './middlewares/timeout.mjs';
import { upcaseMethod } from './middlewares/upcaseMethod.mjs';
import { urlParamsMiddleware } from './urlParamsMiddleware.mjs';
const CLIENT_MIDDLEWARES = [upcaseMethod, localEndpointPrefix, urlParamsMiddleware, queryString, requestBodyMiddleware, localDev, testEnv, timeout, fetch];
const clientFetch = fetchFactory(CLIENT_MIDDLEWARES);
const clientUrlChangingMiddlewares = [localEndpointPrefix, urlParamsMiddleware, queryString];
// little helper that runs middlewares that change url on client to return it as plain text
// this is useful for prefetching the resource
const getClientRequestUrl = (url, options) => {
  const ctx = {
    input: url,
    options: options !== null && options !== void 0 ? options : {}
  };
  for (const mw of clientUrlChangingMiddlewares) {
    mw(ctx, async () => undefined);
  }
  return ctx.input;
};
export { clientFetch, clientFetch as default, getClientRequestUrl };
