import { ListContainer } from "src/components/ListContainer/ListContainer"
import { TileLoadingLarge } from "src/components/TileLoading"
import React from "react"
import styles from "./LoadingList.module.scss"

export const LoadingList = () => {
  return (
    <ListContainer className={styles.loadingContainer}>
      <li>
        <div className="px-[2.25rem] py-24">
          <TileLoadingLarge />
        </div>
      </li>
      <li>
        <div className="px-[2.25rem] py-24">
          <TileLoadingLarge />
        </div>
      </li>
      <li>
        <div className="px-[2.25rem] py-24">
          <TileLoadingLarge />
        </div>
      </li>
    </ListContainer>
  )
}
