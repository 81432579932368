import { Container, Content } from "@kaizen/components"
import React, { PropsWithChildren } from "react"
import styles from "./LayoutContainers.module.scss"

export const OneColumn: React.FC<
  PropsWithChildren<{}> & { marginLarge?: boolean }
> = ({ children }) => (
  <Container>
    <Content classNameOverride={"p-0"}>
      <div className={styles["col-1"]}>{children}</div>
    </Content>
  </Container>
)
