import { Card, CardVariants } from "@kaizen/components"
import React, { PropsWithChildren } from "react"
import classnames from "classnames"
import styles from "./TileContainer.module.scss"

interface TileContainerProps {
  variant?: CardVariants
  classNameOverride?: string
}

export const TileContainer: React.FC<PropsWithChildren<TileContainerProps>> = ({
  variant,
  classNameOverride,
  children,
}) => (
  <Card variant={variant} classNameOverride={classNameOverride}>
    <div className={classnames(styles.container)}>{children}</div>
  </Card>
)
