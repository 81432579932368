import { determineEnvironment } from '@cultureamp/frontend-env/lib';
var _a;
var TIMEOUT_ERRORS;
(function (TIMEOUT_ERRORS) {
  TIMEOUT_ERRORS[TIMEOUT_ERRORS["ETIMEDOUT"] = 504] = "ETIMEDOUT";
})(TIMEOUT_ERRORS || (TIMEOUT_ERRORS = {}));
/**
 * Navigator.connection is experimental and as of writing not supported in Safari and Firefox.
 * DEFAULT_TIMEOUT_MS detects whether `fetch` is being called from a client or server, and
 * if we can detect the effectiveType from a supported browser, that information is used to
 * set the timeout. Because this is still experiemental, the interface needs to be extended
 * to add the missing types.
 * https://caniuse.com/mdn-api_navigator_connection
 */
const customNavigator =
// it is inside condition so not sure why eslint complains
// eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
typeof navigator === "undefined" ? null : navigator;
const connectionType = ((_a = (customNavigator === null || customNavigator === void 0 ? void 0 : customNavigator.connection) || (customNavigator === null || customNavigator === void 0 ? void 0 : customNavigator.mozConnection) || (customNavigator === null || customNavigator === void 0 ? void 0 : customNavigator.webkitConnection)) === null || _a === void 0 ? void 0 : _a.effectiveType) || "";
const SERVER_DEFAULT_TIMEOUT_MS = 800;
const CLIENT_DEFAULT_TIMEOUT_MS = 3000;
const MOBILE_2G_DEFAULT_TIMEOUT_MS = 5000;
const DEFAULT_TIMEOUT_MS = typeof window === "undefined" ? SERVER_DEFAULT_TIMEOUT_MS : ["slow-2g", "2g"].includes(connectionType) ? MOBILE_2G_DEFAULT_TIMEOUT_MS : CLIENT_DEFAULT_TIMEOUT_MS;
const ENABLED_ENVIRONMENTS = ["production", "staging", "development"];
const timeout = (ctx, next) => {
  // in tests we don't want to timeout network to allow checking for loading states
  if (!ENABLED_ENVIRONMENTS.includes(determineEnvironment().realm)) {
    return next();
  }
  if (!ctx.options.timeout) {
    ctx.options.timeout = DEFAULT_TIMEOUT_MS;
  }
  if (!ctx.options.abortController) {
    ctx.options.abortController = new AbortController();
  }
  ctx.options.signal = ctx.options.abortController.signal;
  return new Promise(resolve => {
    let resolved = false;
    const timeout = setTimeout(() => {
      var _a;
      (_a = ctx.options.abortController) === null || _a === void 0 ? void 0 : _a.abort();
      if (!ctx.response) {
        ctx.response = new Response(`{ "message": "ETIMEDOUT ${ctx.options.timeout}ms" }`, {
          status: TIMEOUT_ERRORS.ETIMEDOUT,
          statusText: "ETIMEDOUT"
        });
      }
      if (!resolved) {
        resolved = true;
        return resolve();
      }
    }, ctx.options.timeout);
    return next().catch(err => {
      if ((err === null || err === void 0 ? void 0 : err.name) !== "AbortError") throw err;
    }).finally(() => clearTimeout(timeout)).then(res => {
      // if timeout happens it resolves the parent promise but this sub promise may keep running (missing AbortController)
      // so we need to make check for it to avoid resolving already resolved promise (system error)
      if (!resolved) {
        resolved = true;
        return resolve(res);
      }
    });
  });
};
export { DEFAULT_TIMEOUT_MS, TIMEOUT_ERRORS, timeout };
