// Note: V1 button needed when href is used future update required
import { Button } from "@kaizen/components/v1/actions"
import {
  FormattedMessage,
  detectDirection,
  useIntl,
} from "@cultureamp/i18n-react-intl"
import { Heading, Text } from "@kaizen/components"
import { Icon } from "@kaizen/components/future"
import { RetentionRateLoading } from "./RetentionRateLoading"
import { TileContainer } from "src/components/TileBlock"
import { ampli } from "src/ampli"
import { useTileNameTrackerContext } from "src/components/TileDisplayedTracker/TileDisplayedTracker"
import { useTrackOnView } from "@cultureamp/analytics"
import React from "react"
import styles from "./RetentionRateTile.module.scss"

const RetentionRateTile: React.FC<{
  isWarmup?: boolean
  isInFeed?: boolean
}> = ({ isWarmup, isInFeed }) => {
  const { recordTilesDisplayed } = useTileNameTrackerContext()
  const intl = useIntl()
  const textDirection = detectDirection(intl.locale)

  useTrackOnView(
    () => recordTilesDisplayed(["yourOrganisation.exploreAnalytics"]),
    !isWarmup,
  )

  if (isWarmup) {
    return <RetentionRateLoading />
  }

  return (
    <TileContainer>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className="mb-12">
            <Heading variant="heading-3" classNameOverride={styles.heading}>
              <FormattedMessage
                id="tiles.discover.analytics.retention_rate.title"
                defaultMessage="Retention analytics"
                description="Title of a section displaying analytics on employee retention"
              />
            </Heading>
          </div>
          <Text variant="small" classNameOverride={styles.description}>
            <FormattedMessage
              id="tiles.discover.analytics.retention_rate.description"
              defaultMessage="Understand which groups to focus on and what's motivating people to stay or go."
              description="Descriptive text explaining how to interpret the presented information. 'Groups' refers to sub-groups of employees"
            />
          </Text>
        </div>

        <div className={styles.cta}>
          <Button
            label={intl.formatMessage({
              id: "tiles.discover.analytics.retention_rate.cta",
              defaultMessage: "Explore analytics",
              description:
                "A call to action. A button that encourages admins to visit the analytics page",
            })}
            icon={
              textDirection === "ltr" ? (
                <Icon name="arrow_forward" isPresentational isFilled />
              ) : (
                <Icon name="arrow_back" isPresentational isFilled />
              )
            }
            iconPosition="end"
            href="/app/analytics"
            onClick={() =>
              isInFeed
                ? ampli.homepageTileClicked({
                    "Tile name": "explore analytics",
                    "Tile type": "your organisation",
                    "Feed section": undefined,
                  })
                : ampli.unifiedHomeTileClicked({
                    "Tile name": "explore analytics",
                    "Tile type": "your organisation",
                  })
            }
          />
        </div>
      </div>
    </TileContainer>
  )
}

export default RetentionRateTile
