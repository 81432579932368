import { Icon } from "@kaizen/components/future"
import { Text } from "@kaizen/components"
import React from "react"
import classnames from "classnames"
import styles from "./TileStat.module.scss"

interface TileStatProps {
  value: number | null | undefined
  icon?: React.ReactElement
  label: string | React.ReactElement | null | undefined
  units?: string
  changeInValue?: number | null
  showChangeInValue?: boolean
  textAlignment?: "left" | "center" | "right"
}

export const TileStat: React.FC<TileStatProps> = ({
  icon,
  value,
  label,
  units,
  textAlignment = "left",
  changeInValue,
  showChangeInValue = true,
}) => {
  if (value === null || value === undefined || isNaN(value)) return null

  return (
    <div className={classnames(styles.statistic, styles[textAlignment])}>
      <div className={styles.value}>
        {value}
        {units && <span className={styles.units}>{units}</span>}
        {!!changeInValue && (
          <Text
            variant="body"
            color={changeInValue > 0 ? "positive" : "negative"}
          >
            {changeInValue > 0 ? (
              <Icon name="arrow_upward" isPresentational />
            ) : (
              <Icon name="arrow_downward" isPresentational />
            )}
            {showChangeInValue && changeInValue}
          </Text>
        )}
      </div>
      {label && (
        <div className={classnames({ [styles.iconWithText]: icon })}>
          <Text
            classNameOverride={styles.label}
            variant="body"
            color="dark-reduced-opacity"
          >
            {icon}
            {typeof label === "string" ? <span>{label}</span> : label}
          </Text>
        </div>
      )}
    </div>
  )
}
