import * as scenarios from '../../ca-frontend-apis-alias/scenarios.mjs';
import { getServer } from '../../ca-frontend-apis-alias/msw.mjs';
import { defaultScenario } from '../../localDev/constants.mjs';
import { determineEnvironment } from '@cultureamp/frontend-env';
const LocalDevScenarioHeader = "X-Local-Dev-Scenario";
const setLocalDevScenarioHeader = (ctx, scenarioName) => {
  if (scenarioName) {
    const headers = new Headers(ctx.options.headers);
    headers.set(LocalDevScenarioHeader, scenarioName);
    ctx.options.headers = headers;
  }
};
const getScenarioName = headers => {
  var _a;
  const incomingHeaders = new Headers(headers);
  const referer = incomingHeaders.get("referer");
  if (!referer) {
    return defaultScenario;
  }
  // after service worker has been loaded, we need to rely on LocalDevScenarioHeader instead of referrer as referrer would be http://localhost:3000/app/home/mockServiceWorker.js which doesn't have the scenario query string
  if (referer.includes("mockServiceWorker.js")) {
    const scenarioName = incomingHeaders.get(LocalDevScenarioHeader);
    return scenarioName !== null && scenarioName !== void 0 ? scenarioName : defaultScenario;
  }
  // for preload request, referrer would be normal (not service worker) so we can rely on that to pick scenario
  const refererUrl = new URL(referer);
  return (_a = refererUrl.searchParams.get("scenario")) !== null && _a !== void 0 ? _a : defaultScenario;
};
const localDev = async (ctx, next) => {
  if (determineEnvironment().realm !== "localhost") {
    return next();
  }
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    const scenarioName = url.searchParams.get("scenario");
    setLocalDevScenarioHeader(ctx, scenarioName);
    await window.mswWorker;
    return next();
  } else {
    if (!ctx.options.incomingHeaders) {
      return next();
    }
    const scenarioName = getScenarioName(ctx.options.incomingHeaders);
    // @ts-ignore
    const selectedScenario = scenarios[scenarioName];
    if (selectedScenario) {
      const server = getServer();
      server.use(...selectedScenario);
      server.listen();
    }
    const response = await next();
    return response;
  }
};
export { localDev };
