import { ListContainer } from "src/components/ListContainer/ListContainer"
import { TileLoadingLarge } from "src/components/TileLoading"
import { Well } from "@kaizen/components"
import React from "react"

export const LoadingList = () => {
  return (
    <ListContainer className="flex flex-col">
      <li>
        <Well>
          <div className="px-[2.25rem] py-24">
            <TileLoadingLarge />
          </div>
        </Well>
      </li>
      <li>
        <Well>
          <div className="px-[2.25rem] py-24">
            <TileLoadingLarge />
          </div>
        </Well>
      </li>
      <li>
        <Well>
          <div className="px-[2.25rem] py-24">
            <TileLoadingLarge />
          </div>
        </Well>
      </li>
    </ListContainer>
  )
}
