import createHttpError from 'http-errors';

/**
 * Safely reads & parses body from response
 * @param response
 */
function responseBody(response) {
  var _a;
  const contentType = (_a = response.headers.get("content-type")) === null || _a === void 0 ? void 0 : _a.toLowerCase();
  if (contentType === null || contentType === void 0 ? void 0 : contentType.includes("json")) {
    return response.clone().json();
  }
  return response.clone().text();
}
async function responseError(response, url) {
  throw createHttpError(response.status, `[${response.status} ${response.statusText}] while fetching "${url}".`, {
    path: url,
    data: await responseBody(response),
    statusText: response.statusText
  });
}
export { responseBody, responseError };
