import parseUrl from 'url-parse';
const urlParamsMiddleware = (ctx, next) => {
  var _a;
  if (!ctx.options.params) return next();
  // we need to use url-parse instead of `new Url` to allow pathname to include []. `new Url` throws exception with that
  const url = parseUrl(ctx.input.toString(), {});
  if (!url.pathname.includes("[")) return next();
  let pathname = url.pathname;
  for (const [key, value] of Object.entries(ctx.options.params)) {
    pathname = pathname.replace(new RegExp(`\\[${key}\\]`, "g"), value.toString());
  }
  // bind pageParam if pathname has its placeholder
  pathname = pathname.replace(/\[pageParam\]/g, (_a = ctx.options.pageParam) !== null && _a !== void 0 ? _a : "");
  url.set("pathname", pathname);
  ctx.input = url.toString();
  return next();
};
export { urlParamsMiddleware };
