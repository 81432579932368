import { getLoginUrl } from '@cultureamp/redirect-to-login';
const redirectUnauthorised = async (ctx, next) => {
  var _a;
  await next();
  const isUnauthorised = ((_a = ctx.response) === null || _a === void 0 ? void 0 : _a.status) === 401;
  if (isUnauthorised && !ctx.options.disableAuthRedirect) {
    window.location.href = getLoginUrl(window.location.toString());
    /*
      Previously, the respose was already determined to be an error, causing components to show an error state flash prior to redirecting.
      This is to allow React to continue showing a loading state whilst the browser has time to react to the redirect.
    */
    await new Promise(resolve => setTimeout(resolve, 3000));
  }
};
export { redirectUnauthorised };
