// Refers to this issue (https://github.com/whatwg/fetch/issues/50) and this (https://fetch.spec.whatwg.org/#concept-method-normalize)
// By default all methods are upcase by default by fetch api
// But patch is considered non standard HTTP-method and won't be upcase by default
// hence we are doing this here by default so consumers dont need to care about it
const upcaseMethod = async (ctx, next) => {
  if (ctx.options.method) {
    ctx.options.method = ctx.options.method.toUpperCase();
  }
  return next();
};
export { upcaseMethod };
